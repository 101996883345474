<template>
  <div class="contents">
    <div class="card shadow">
      <div class="card-body">
        <div class="text-left">
          <input type="checkbox" @click="selectAll" v-model="allSelected"/><label class="bold ml10">전체선택</label>
        </div>
        <div class="btnwrap-right">
          <button type="button" @click="loadDoc('setup',null,checkedList)" class="btn btn-primary pr20 pl20 mr5">인증서 일괄 등록</button>
          <button type="button" @click="saveAuth(checkedList)" class="btn btn-primary pr20 pl20 mr5">일괄 저장</button>
          <button type="button" @click="deleteAuth(checkedList)" class="btn btn-black pr20 pl20">일괄 삭제</button>
        </div>
        <hr class="hr-title"/>
        <div id="hometaxWrap" v-if="userInfo.CLIENT_ID != 'yoglro'">
          <div class="text-left mt10 mb10">
            <input type="checkbox" name="chkbox" v-model="checkedList" value="HOMETAX"/><label class="bold ml10 bg-greenlight">홈택스</label>
            <span class="ml10 text-red">갱신된 인증서는 홈택스에서 먼저 등록해주세요. <a href="https://hometax.go.kr/" target="_blank" class="bold ml10">홈택스 바로가기 (로그인-인증서 등록)</a> </span>
          </div>
          <div class="btnwrap-right">
            <button type="button" @click="loadDoc('setup',null,['HOMETAX'])" class="btn btn-primary pr20 pl20 mr5">인증서 등록</button>
            <button type="button" @click="saveAuth(['HOMETAX'])" class="btn btn-primary pr20 pl20 mr5">저장</button>
            <button type="button" @click="deleteAuth(['HOMETAX'])" class="btn btn-black pr20 pl20">삭제</button>
          </div>
          <div class="ml23">
            <table class="table02 ">
              <caption>홈택스 인증정보 표</caption>
              <colgroup>
                <col style="width:200px"/>
                <col v-if="hasHOMETAXAuth && status_HOMETAX === 'VALID'" style="width:207px"/>
                <col v-if="hasHOMETAXAuth && status_HOMETAX === 'VALID'" style="width:200px"/>
                <col />
              </colgroup>
              <tbody>
              <tr>
                <th class="tx_cnt bg-gray-100"><span>인증서등록상태</span></th>
                <td class="tx_lf" :class="{'txt-primary' : (hasHOMETAXAuth && status_HOMETAX === 'VALID') , 'txt-danger' : failRegHOMETAXAuth}" colspan="3">{{HOMETAXAuthState}}&nbsp;{{failRegHOMETAXMsg}}</td>
              </tr>
              <tr>
                <th class="tx_cnt bg-gray-100"><span>인증서 명</span></th>
                <td><input type="text" v-model="certNm_HOMETAX" disabled></td>
                <td v-if="hasHOMETAXAuth && status_HOMETAX === 'VALID'" class="tx_cnt bg-gray-100"><span class="bold">인증서 만료일자</span></td>
                <td v-if="hasHOMETAXAuth && status_HOMETAX === 'VALID'" class="tx_lf">{{endDt_HOMETAX}}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <hr class="mt20 mb20"/>
        </div><!-- hometax wrap -->

        <div id="nhisWrap">
          <div class="text-left mt10" style="vertical-align:bottom">
            <input type="checkbox" name="chkbox" v-model="checkedList" value="NHIS"/><label class="bold ml10 bg-greenlight">국민건강보험공단</label>
            <a href="https://si4n.nhis.or.kr/" target="_blank" class="ml10">바로가기</a>
          </div>
          <div class="btnwrap-right">
            <button type="button" @click="loadDoc('setup',null,['NHIS'])" class="btn btn-primary pr20 pl20 mr5">인증서 등록</button>
            <button type="button" @click="saveAuth(['NHIS'])" class="btn btn-primary pr20 pl20 mr5">저장</button>
            <button type="button" @click="deleteAuth(['NHIS'])" class="btn btn-black pr20 pl20">삭제</button>
          </div>
          <div class="ml23">
            <table class="table02">
              <caption>국민건강보험 인증정보 표</caption>
              <colgroup>
                <col style="width:200px"/>
                <col v-if="hasNHISAuth && status_NHIS === 'VALID'" style="width:207px"/>
                <col v-if="hasNHISAuth && status_NHIS === 'VALID'" style="width:200px"/>
                <col />
              </colgroup>
              <tbody>
              <tr>
                <th class="tx_cnt bg-gray-100"><span>인증서등록상태</span></th>
                <td class="tx_lf" :class="{'txt-primary' : (hasNHISAuth && status_NHIS === 'VALID') , 'txt-danger' : failRegNHISAuth}" colspan="3">{{NHISAuthState}}&nbsp;{{failRegNHISMsg}}</td>
              </tr>
              <tr>
                <th class="tx_cnt bg-gray-100"><span>인증서 명</span></th>
                <td><input type="text" v-model="certNm_NHIS" disabled></td>
                <td v-if="hasNHISAuth && status_NHIS === 'VALID'" class="tx_cnt bg-gray-100"><span class="bold">인증서 만료일자</span></td>
                <td v-if="hasNHISAuth && status_NHIS === 'VALID'" class="tx_lf">{{endDt_NHIS}}</td>
              </tr>
              <tr>
                <th class="tx_cnt bg-gray-100"><span>사업장관리번호</span></th>
                <td colspan="3"><input type="text" v-model="corpHManageNo"></td>
              </tr>
              <tr v-if="isCorp && isPerson">
                <th class="tx_cnt bg-gray-100"><span>인증서 구분</span></th>
                <td class="tx_lf pl20" colspan="3">
                  <label for="" class="w260"><input type="radio" name="certKind" id="corp" value="CORP" v-model="pickedBizDetailKind"> 사업자인증서(개인사업자, 법인사업자)</label>
                  <label for="" class="w240"><input type="radio" name="certKind" id="person" value="PERSON" v-model="pickedBizDetailKind"> 대표자 개인인증서(개인사업자)</label>
                </td>
              </tr>
              <tr v-if="isCorp && pickedBizDetailKind === 'PERSON'">
                <th class="tx_cnt bg-gray-100"><span>웹 아이디</span></th>
                <td class="tx_lf" colspan="3"><input v-model="siteId" type="text"/></td>
              </tr>
              </tbody>
            </table>
          </div>
          <hr class="mt20"/>
        </div><!-- nhis wrap -->

        <div id="npsWrap">
          <div class="text-left mt10 mb10">
            <input type="checkbox" name="chkbox" v-model="checkedList" value="NPS"/><label class="bold ml10 bg-greenlight">국민연금공단</label>
            <a href="https://minwon.nps.or.kr/" target="_blank" class="ml10">바로가기</a>
          </div>
          <div class="btnwrap-right">
            <button type="button" @click="loadDoc('setup',null,['NPS'])" class="btn btn-primary pr20 pl20 mr5">인증서 등록</button>
            <button type="button" @click="saveAuth(['NPS'])" class="btn btn-primary pr20 pl20 mr5">저장</button>
            <button type="button" @click="deleteAuth(['NPS'])" class="btn btn-black pr20 pl20">삭제</button>
          </div>
          <div class="ml23">
            <table class="table02 ">
              <caption>국민연금공단 인증정보 표</caption>
              <colgroup>
                <col style="width:200px"/>
                <col v-if="hasNPSAuth && status_NPS === 'VALID'" style="width:207px"/>
                <col v-if="hasNPSAuth && status_NPS === 'VALID'" style="width:200px"/>
                <col />
              </colgroup>
              <tbody>
              <tr>
                <th class="tx_cnt bg-gray-100"><span>인증서등록상태</span></th>
                <td class="tx_lf" :class="{'txt-primary' : (hasNPSAuth && status_NPS === 'VALID') , 'txt-danger' : failRegNPSAuth}" colspan="3">{{NPSAuthState}}&nbsp;{{failRegNPSMsg}}</td>
              </tr>
              <tr>
                <th class="tx_cnt bg-gray-100"><span>인증서 명</span></th>
                <td><input type="text" v-model="certNm_NPS" disabled>
                <td v-if="hasNPSAuth && status_NPS === 'VALID'" class="tx_cnt bg-gray-100"><span class="bold">인증서 만료일자</span></td>
                <td v-if="hasNPSAuth && status_NPS === 'VALID'" class="tx_lf">{{endDt_NPS}}</td>
              </tr>
              <tr v-if="isCorp">
                <th class="tx_cnt bg-gray-100"><span>사업장관리번호</span></th>
                <td class="tx_lf" colspan="3"><input v-model="corpYManageNo" type="text"/></td>
              </tr>
              </tbody>
            </table>
          </div>
          <hr class="mt20 mb20"/>
        </div><!-- nps wrap -->

        <div id="mediWrap"  v-if="userInfo.CLIENT_ID != 'yoglro'">
          <div class="text-left mt10 mb10">
            <input type="checkbox" name="chkbox" v-model="checkedList" value="MEDI"/><label class="bold ml10 bg-greenlight">요양기관정보마당</label>
            <a href="https://medicare.nhis.or.kr/" target="_blank" class="bold ml10">바로가기</a>
          </div>
          <div class="btnwrap-right">
            <button type="button" @click="loadDoc('setup',null,['MEDI'])" class="btn btn-primary pr20 pl20 mr5">인증서 등록</button>
            <button type="button" @click="saveAuth(['MEDI'])" class="btn btn-primary pr20 pl20 mr5">저장</button>
            <button type="button" @click="deleteAuth(['MEDI'])" class="btn btn-black pr20 pl20">삭제</button>
          </div>
          <div class="ml23">
            <table class="table02 ">
              <caption>요양기관정보마당 인증정보 표</caption>
              <colgroup>
                <col style="width:200px"/>
                <col v-if="hasMEDIAuth && status_MEDI === 'VALID'" style="width:207px"/>
                <col v-if="hasMEDIAuth && status_MEDI === 'VALID'" style="width:200px"/>
                <col />
              </colgroup>
              <tbody>
              <tr>
                <th class="tx_cnt bg-gray-100"><span>인증서등록상태</span></th>
                <td class="tx_lf" :class="{'txt-primary' : (hasMEDIAuth && status_MEDI === 'VALID') , 'txt-danger' : failRegMEDIAuth}" colspan="3">{{MEDIAuthState}}&nbsp;{{failRegMEDIMsg}}</td>
              </tr>
              <tr>
                <th class="tx_cnt bg-gray-100"><span>인증서 명</span></th>
                <td><input type="text" v-model="certNm_MEDI" disabled></td>
                <td v-if="hasMEDIAuth && status_MEDI === 'VALID'" class="tx_cnt bg-gray-100"><span class="bold">인증서 만료일자</span></td>
                <td v-if="hasMEDIAuth && status_MEDI === 'VALID'" class="tx_lf">{{endDt_MEDI}}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <hr class="mt20 mb20"/>
        </div><!-- medi wrap -->

        <div id="hiraWrap" v-if="userInfo.CLIENT_ID != 'yoglro'">
          <div class="text-left mt10 mb10">
            <input type="checkbox" name="chkbox" v-model="checkedList" value="HIRA"/><label class="bold ml10 bg-greenlight">요양기관업무포탈</label>
            <span class="ml10 text-red">갱신된 인증서는 요양기관업무포탈 사이트에서 먼저 등록해주세요. <a href="https://biz.hira.or.kr/" target="_blank" class="bold ml10">요양기관업무포탈 바로가기 (인증서 등록)</a> </span>
          </div>
          <div class="btnwrap-right">
            <button type="button" @click="loadDoc('setup',null,['HIRA'])" class="btn btn-primary pr20 pl20 mr5">인증서 등록</button>
            <button type="button" @click="saveAuth(['HIRA'])" class="btn btn-primary pr20 pl20 mr5">저장</button>
            <button type="button" @click="deleteAuth(['HIRA'])" class="btn btn-black pr20 pl20">삭제</button>
          </div>
          <div class="ml23">
            <table class="table02 ">
              <caption>요양기관업무포탈 인증정보 표</caption>
              <colgroup>
                <col style="width:200px"/>
                <col v-if="hasHIRAAuth && status_HIRA === 'VALID'" style="width:207px"/>
                <col v-if="hasHIRAAuth && status_HIRA === 'VALID'" style="width:200px"/>
                <col />
              </colgroup>
              <tbody>
              <tr>
                <th class="tx_cnt bg-gray-100"><span>인증서등록상태</span></th>
                <td class="tx_lf" :class="{'txt-primary' : (hasHIRAAuth && status_HIRA === 'VALID') , 'txt-danger' : failRegHIRAAuth}" colspan="3">{{HIRAAuthState}}&nbsp;{{failRegHIRAMsg}}</td>
                <!-- <td class="tx_lf txt-danger">미검증</td> -->
              </tr>
              <tr>
                <th class="tx_cnt bg-gray-100"><span>인증서 명</span></th>
                <td><input type="text" v-model="certNm_HIRA" disabled></td>
                <td v-if="hasHIRAAuth && status_HIRA === 'VALID'" class="tx_cnt bg-gray-100"><span class="bold">인증서 만료일자</span></td>
                <td v-if="hasHIRAAuth && status_HIRA === 'VALID'" class="tx_lf">{{endDt_HIRA}}</td>
              </tr>
              <tr>
                <th class="tx_cnt bg-gray-100"><span>요양기관번호</span></th>
                <td colspan="3" ><input type="text" v-model="hiraNo" maxlength="8">  <span>다중계정인 경우 요양기관번호를 입력 하세요.</span></td>
              </tr>
              </tbody>
            </table>
          </div>

          <hr class="mt20 mb20"/>
        </div><!-- hira wrap -->
      </div><!-- card body -->
    </div><!-- card shadow -->
  </div><!-- contents -->
</template>
<script>
export default {
	name: "pageAuthPublicCorporation",
  props: {
    role: {
      type: String,
    },
    userInfo : Object
  },
	data:() => {
		return {
			prefix : "api/auth/publiccorporation",
			pickedBizDetailKind: "CORP",
			companyInfo: {},
			
			//checkbox_attrs
			checkedList: [],
			allSelected: false,

      //HOMETAX_auth_attrs
      sendData_HOMETAX : {},
      executeData_HOMETAX : {},
      certNm_HOMETAX : "",
      status_HOMETAX : "",
      endDt_HOMETAX : "",

			//NHIS_auth_attrs
			sendData_NHIS : {},
			executeData_NHIS : {},
			certNm_NHIS : "",
      status_NHIS : "",
			siteId : "",
      corpHManageNo : "",
      endDt_NHIS : "",
			
			//NPS_auth_attrs
			sendData_NPS : {},
			executeData_NPS : {},
			certNm_NPS : "",
      status_NPS : "",
			corpYManageNo : "",
      endDt_NPS : "",
			
			//MEDI_auth_attrs
			sendData_MEDI : {},
			executeData_MEDI : {},
			certNm_MEDI : "",
      status_MEDI : "",
      endDt_MEDI : "",
			
			//HIRA_auth_attrs
			sendData_HIRA : {},
			executeData_HIRA : {},
			certNm_HIRA : "",
      status_HIRA : "",
      endDt_HIRA : "",
      hiraNo: "",
			
			//multi_auth_attrs;
			authDataArr: [],
			
			
			//is_corp_flag
			isCorp : false,
			isPerson : false,
			
			//has_auth_flags
      hasHOMETAXAuth : false,
			hasNHISAuth : false,
			hasNPSAuth : false,
			hasMEDIAuth : false, 
			hasHIRAAuth : false,

			//fail_reg_auth_flags
      failRegHOMETAXAuth : false,
			failRegNHISAuth : false,
			failRegNPSAuth : false,
			failRegMEDIAuth : false,
			failRegHIRAAuth : false,
			
			//fail_reg_auth_message
      failRegHOMETAXMsg : "",
			failRegNHISMsg : "",
			failRegNPSMsg : "",
			failRegMEDIMsg : "",
			failRegHIRAMsg : "",
			
		}
	},
	watch:{
		pickedBizDetailKind: function(val){
			if(val === "CORP"){
				this.siteId = "";
			}
		}
	},
	computed:{
    HOMETAXAuthState: function(){
      return (this.hasHOMETAXAuth) ? ((this.status_HOMETAX === 'VALID') ? '정상' : (this.status_HOMETAX === 'UNVALID') ? '처리중' : '실패') :  '미등록' ;
    },
		NHISAuthState: function(){
			return (this.hasNHISAuth) ? ((this.status_NHIS === 'VALID') ? '정상' : (this.status_NHIS === 'UNVALID') ? '처리중' : '실패') : '미등록' ;
		},
		NPSAuthState: function(){
			return (this.hasNPSAuth) ? ((this.status_NPS === 'VALID') ? '정상' : (this.status_NPS === 'UNVALID') ? '처리중' : '실패') : '미등록' ;
		},
		MEDIAuthState: function(){
			return (this.hasMEDIAuth) ? ((this.status_MEDI === 'VALID') ? '정상' : (this.status_MEDI === 'UNVALID') ? '처리중' : '실패') : '미등록' ;
		},
		HIRAAuthState: function(){
			return (this.hasHIRAAuth) ? ((this.status_HIRA === 'VALID') ? '정상' : (this.status_HIRA === 'UNVALID') ? '처리중' : '실패') : '미등록' ;
		}
	},
	methods:{
		reset: function(){
			Object.assign(this.$data, this.$options.data.apply(this));
		},
    // 인증정보 조회
		initInquriy: function(){
			this.reset();
			
			this.$cAxios.post(this.prefix+"/auth/init/inquriy",{
			}).then((res)=>{
				this.companyInfo = res.data.companyInfo;
				
				if(res.data.companyInfo.bizKind === "CORP" ) {
				  if(res.data.companyInfo.corpYManageNo != null){
					  this.corpYManageNo = res.data.companyInfo.corpYManageNo;  //국민연금공단 사업장관리번호
          }
				  if(res.data.companyInfo.corpHManageNo != null){
					  this.corpHManageNo = res.data.companyInfo.corpHManageNo;  //국민건강보험공단 사업장관리번호
          }
					this.isCorp = true;
				}

				if(res.data.companyInfo.bizDetailKind === "INDI") this.isPerson = true;

        if(res.data.HOMETAX != null){
          this.hasHOMETAXAuth = true;
          this.certNm_HOMETAX = res.data.HOMETAX.certNm;
          this.status_HOMETAX = res.data.HOMETAX.status;
          if(res.data.HOMETAX.statusDesc){
            this.failRegHOMETAXMsg = " : (" + res.data.HOMETAX.statusDesc + ")";
          }
          this.endDt_HOMETAX = res.data.HOMETAX.certEndDate;
        }
				
				if(res.data.NHIS != null){
					this.hasNHISAuth = true;
					this.certNm_NHIS = res.data.NHIS.certNm;
					this.siteId = res.data.NHIS.additionalInfo2;
          this.status_NHIS = res.data.NHIS.status;
          if(res.data.NHIS.statusDesc){
            this.failRegNHISMsg = " : (" + res.data.NHIS.statusDesc + ")";
          }
          this.endDt_NHIS = res.data.NHIS.certEndDate;
				}
				
				if(res.data.NPS != null){
					this.hasNPSAuth = true;
					this.certNm_NPS = res.data.NPS.certNm;
          this.status_NPS = res.data.NPS.status;
          if(res.data.NPS.statusDesc) {
            this.failRegNPSMsg = " : (" + res.data.NPS.statusDesc + ")";
          }
          this.endDt_NPS = res.data.NPS.certEndDate;
				}
				
				if(res.data.MEDI != null){
					this.hasMEDIAuth = true;
					this.certNm_MEDI = res.data.MEDI.certNm;
          this.status_MEDI = res.data.MEDI.status;
          if(res.data.MEDI.statusDesc) {
            this.failRegMEDIMsg = " : (" + res.data.MEDI.statusDesc + ")";
          }
          this.endDt_MEDI = res.data.MEDI.certEndDate;
				}
				
				if(res.data.HIRA != null){
					this.hasHIRAAuth = true;
					this.certNm_HIRA = res.data.HIRA.certNm;
          this.status_HIRA = res.data.HIRA.status;
          this.hiraNo = res.data.HIRA.additionalInfo1;
          if(res.data.HIRA.statusDesc) {
            this.failRegHIRAMsg = " : (" + res.data.HIRA.statusDesc + ")";
          }
          this.endDt_HIRA = res.data.HIRA.certEndDate;
				}
				
				
			});
			
			
		
		},
		saveAuth: function(scrapSites){
			if(scrapSites.length < 1 ) {
				alert("저장하실 인증정보를 선택해주십시오.");
				return false;
			}
			
			let isAllNonRegi = true;
			for(var i = 0 ; i < scrapSites.length; i++){
				let scrapSite = scrapSites[i];
				switch(scrapSite){
          case 'HOMETAX' :
            if(this.hasHOMETAXAuth) isAllNonRegi = false;
            if(!this.certNm_HOMETAX.trim()){
              alert("홈택스 인증서를 등록해주십시오.");
              return false;
            }
            break;
					case 'NHIS' :
						if(this.hasNHISAuth) isAllNonRegi = false;
						//bizKind 와 bizDetailKind 를 확인하고  CORP & INDI 일 경우 웹 아이디까지 확인;
						if(!this.certNm_NHIS.trim()) {
							alert("국민건강보험공단 인증서를 등록해주십시오.");
							return false;
						}
						
						if((this.isCorp == true && this.isPerson == true && this.pickedBizDetailKind == "PERSON") && !this.siteId.trim()){
							alert("국민건강보험공단 아이디를 확인해주십시오.");
							return false;
						}
						
						break;
					case 'NPS' : 
						if(this.hasNPSAuth) isAllNonRegi = false;
						if(!this.certNm_NPS.trim()){
							alert("국민연금공단 인증서를 등록해주십시오.");
							return false;
						}

						if(this.isCorp == true && !this.corpYManageNo.trim()){
							alert("국민연금공단 사업장관리번호를 확인해주십시오.");
							return false;
						}
						break;
					case 'MEDI' : 
						if(this.hasMEDIAuth) isAllNonRegi = false;
						if(!this.certNm_MEDI.trim()){
							alert("요양기관정보마당 인증서를 등록해주십시오.");
							return false;
						}
						break;
					case 'HIRA' :
						if(this.hasHIRAAuth) isAllNonRegi = false;
						if(!this.certNm_HIRA.trim()){
							alert("요양기관업무포탈 인증서를 등록해주십시오.");
							return false;
						}
						break;
				}
				
				if(!isAllNonRegi) break;
			}
			
			if(!isAllNonRegi){
				alert("미등록 상태의 인증정보만 선택해 주십시오.");
				return false;
			}
			
			function siteSwitch (site, executeDatas, sendDatas, $this){
				switch (site){
          case 'HOMETAX' :
            $this.sendData_HOMETAX.scrapGroupCode = 'HOMETAX';
            executeDatas.push($this.executeData_HOMETAX);
            sendDatas.push($this.sendData_HOMETAX);
            break;
					case 'NHIS' :
						$this.sendData_NHIS.scrapGroupCode = 'NHIS';
						$this.sendData_NHIS.additionalInfo2 = $this.siteId.trim();
						$this.sendData_NHIS.corpManageNo = $this.corpHManageNo.trim();
						executeDatas.push($this.executeData_NHIS);
						sendDatas.push($this.sendData_NHIS);
						break;
					case 'NPS' :
						$this.sendData_NPS.scrapGroupCode = 'NPS';
						$this.sendData_NPS.corpManageNo = $this.corpYManageNo.trim();
						executeDatas.push($this.executeData_NPS);
						sendDatas.push($this.sendData_NPS);
						break;
					case 'MEDI' :
						$this.sendData_MEDI.scrapGroupCode = 'MEDI';
						executeDatas.push($this.executeData_MEDI);
						sendDatas.push($this.sendData_MEDI);
						break;
					case 'HIRA' :
						$this.sendData_HIRA.scrapGroupCode = 'HIRA';
            $this.sendData_HIRA.additionalInfo1 = $this.hiraNo.trim();
						executeDatas.push($this.executeData_HIRA);
						sendDatas.push($this.sendData_HIRA);
						break;
				}
			}
			
			if(scrapSites.length > 1){
				//일괄저장
				var executeDatas = new Array();
				var sendDatas = new Array();
				
				for(var site of scrapSites){
					siteSwitch(site, executeDatas, sendDatas, this);
				}
				
				this.AuthRegist(executeDatas, sendDatas);
			} else {
				//단건저장
        if( scrapSites[0] == 'HOMETAX'){
          this.sendData_HOMETAX.scrapGroupCode = 'HOMETAX';
          this.AuthRegist([this.executeData_HOMETAX], [this.sendData_HOMETAX]);
        }else if(scrapSites[0] == 'NHIS'){
					this.sendData_NHIS.scrapGroupCode = 'NHIS';
					this.sendData_NHIS.additionalInfo2 = this.siteId.trim();
          this.sendData_NHIS.corpManageNo = this.corpHManageNo.trim();
					this.AuthRegist([this.executeData_NHIS], [this.sendData_NHIS]);
				}else if( scrapSites[0] == 'NPS'){
					this.sendData_NPS.scrapGroupCode = 'NPS';
					this.sendData_NPS.corpManageNo = this.corpYManageNo.trim();
					this.AuthRegist([this.executeData_NPS], [this.sendData_NPS]);
				}else if( scrapSites[0] == 'MEDI'){
					this.sendData_MEDI.scrapGroupCode = 'MEDI';
					this.AuthRegist([this.executeData_MEDI], [this.sendData_MEDI]);	
				}else if( scrapSites[0] == 'HIRA'){
					this.sendData_HIRA.scrapGroupCode = 'HIRA';
          this.sendData_HIRA.additionalInfo1 = this.hiraNo.trim();
					this.AuthRegist([this.executeData_HIRA], [this.sendData_HIRA]);
				}
			}
			
		},
		deleteAuth: function(scrapSites){
			if(scrapSites.length < 1) {
				alert("삭제하실 인증정보를 선택해주세요");
				return false;
			}
			
			let delYn = confirm("선택하신 인증정보를 삭제하시겠습니까?");
			if(!delYn) return false;
			
			if(!this.regAuthCheck(scrapSites)) {
				alert("선택하신 인증정보중 등록되지 않은 인증정보가 포함되어있습니다.");
				return false;
			}

      if(!this.verificationInProgressCheck(scrapSites)){
        alert("저장하신 인증정보를 검증하는 중입니다. 잠시 후 다시 시도해주십시오.");
        return false;
      }
			
			this.$cAxios.post(this.prefix + "/auth/delete", JSON.stringify(scrapSites) ,{
				headers : {
					'Content-Type': 'application/json;'
				}
			}).then(()=>{
				alert("삭제되었습니다.");
				this.initInquriy();
				
			}).catch((err)=>{
				if(err.response) alert(err.response.data.message);
			});
		},
		loadDoc: function (s_op, s_inJson, scrapSites) {
			if(scrapSites.length < 1){
				alert("인증서정보를 등록하실 기관을 선택해주십시오.");
				return false;
			}
			// 미등록 상태로 변경
			// for(var i =0 ; i< scrapSites.length; i++){
			// 	switch(scrapSites[i]){
      //   case "HOMETAX":
      //     this.hasHOMETAXAuth = false;
      //     break;
			// 	case "NHIS":
			// 		this.hasNHISAuth = false;
			// 		break;
			// 	case "NPS":
			// 		this.hasNPSAuth = false;
			// 		break;
			// 	case "MEDI":
			// 		this.hasMEDIAuth = false;
			// 		break;
			// 	case "HIRA":
			// 		this.hasHIRAAuth = false;
			// 		break;
			// 	}
      //
			// }
			
			this.loadNxClient(s_op, s_inJson, scrapSites);
		},
		loadNxClient :  function(v_s_op, v_s_inJson, scrapSites, sendDatas) {
			return new Promise((resolve)=>{
				let sUrl = "https://127.0.0.1:16565/?op=";
				if(v_s_op == 'certSelect'){
					sUrl = "https://127.0.0.1:16566/?op=";
				}
	
				this.$cAxios.post(sUrl+v_s_op, v_s_inJson ,{
					crossDomain: true,
					headers:{
						'Content-type':'application/json; charset=UTF-8',
					},
				}).then((res) =>{
					this.nxClientResult(v_s_op, res.data, scrapSites, sendDatas);
					resolve("success");
				}).catch((err) => {
					if(err.request){ 
						if(err.config.url.indexOf('?op=setup') != -1){
							alert("공인인증서 등록 프로그램 설치가 필요합니다.");
							this.fnNxDownload();
						}
					}
	
				});
			});

		},
		nxClientResult : function (s_op, data, scrapSites, sendData) {
			var errYn = data.errYn;
			if('setup' == s_op){
				if (errYn == 'N') {
					this.loadNxClient('certSelect', '{"certImageUrl": "", "nxKeypad": ""}', scrapSites);
				}
			} else if ('certSelect' == s_op) {
				if(!data.cert_nm.trim()) return false;
				for(var i = 0; i < scrapSites.length; i++){
					let scrapSite = scrapSites[i];
					switch (scrapSite) {
            case 'HOMETAX':
              this.hasHOMETAXAuth = false;
              this.endDt_HOMETAX = "";
              this.failRegHOMETAXMsg = "";
              this.failRegHOMETAXAuth = false;
              this.sendData_HOMETAX = {};
              this.executeData_HOMETAX = {};
              this.certNm_HOMETAX = "";

              this.sendData_HOMETAX.certNm = data.cert_nm;
              this.sendData_HOMETAX.certPubDate = data.pub_dt;
              this.sendData_HOMETAX.certEndDate = data.end_dt;
              this.sendData_HOMETAX.certOrgNm = data.org_nm;
              this.sendData_HOMETAX.certPolicyId = data.oid;
              this.sendData_HOMETAX.certSerialNo = data.sn;
              this.sendData_HOMETAX.password = data.cert_pw;

              this.certNm_HOMETAX = data.cert_nm;

              this.executeData_HOMETAX.file1 = data.file1;
              this.executeData_HOMETAX.file2 = data.file2;
              this.executeData_HOMETAX.cert_pw = data.cert_pw;
              break;
						case 'NHIS':
              this.hasNHISAuth = false;
              this.endDt_NHIS = "";
              this.failRegNHISMsg = "";
              this.failRegNHISAuth = false;
							this.sendData_NHIS = {};
							this.executeData_NHIS = {};
							this.certNm_NHIS = "";
							
							this.sendData_NHIS.certNm = data.cert_nm; 
							this.sendData_NHIS.certPubDate = data.pub_dt;
							this.sendData_NHIS.certEndDate = data.end_dt;
							this.sendData_NHIS.certOrgNm = data.org_nm;
							this.sendData_NHIS.certPolicyId = data.oid;
							this.sendData_NHIS.certSerialNo = data.sn;
							this.sendData_NHIS.password = data.cert_pw;
		
							this.certNm_NHIS = data.cert_nm;
		
							this.executeData_NHIS.file1 = data.file1;
							this.executeData_NHIS.file2 = data.file2;
							this.executeData_NHIS.cert_pw = data.cert_pw;
							break;
						case 'NPS':
              this.hasNPSAuth = false;
              this.endDt_NPS = "";
              this.failRegNPSMsg = "";
              this.failRegNPSAuth = false;
							this.sendData_NPS = {};
							this.executeData_NPS = {};
							this.certNm_NPS = "";
							
							this.sendData_NPS.certNm = data.cert_nm; 
							this.sendData_NPS.certPubDate = data.pub_dt;
							this.sendData_NPS.certEndDate = data.end_dt;
							this.sendData_NPS.certOrgNm = data.org_nm;
							this.sendData_NPS.certPolicyId = data.oid;
							this.sendData_NPS.certSerialNo = data.sn;
							this.sendData_NPS.password = data.cert_pw;
		
							this.certNm_NPS = data.cert_nm;
		
							this.executeData_NPS.file1 = data.file1;
							this.executeData_NPS.file2 = data.file2;
							this.executeData_NPS.cert_pw = data.cert_pw;
							break;
						case 'MEDI':
              this.hasMEDIAuth = false;
              this.endDt_MEDI = "";
              this.failRegMEDIMsg = "";
              this.failRegMEDIAuth = false;
							this.sendData_MEDI = {};
							this.executeData_MEDI = {};
							this.certNm_MEDI = "";
							
							this.sendData_MEDI.certNm = data.cert_nm; 
							this.sendData_MEDI.certPubDate = data.pub_dt;
							this.sendData_MEDI.certEndDate = data.end_dt;
							this.sendData_MEDI.certOrgNm = data.org_nm;
							this.sendData_MEDI.certPolicyId = data.oid;
							this.sendData_MEDI.certSerialNo = data.sn;
							this.sendData_MEDI.password = data.cert_pw;
		
							this.certNm_MEDI = data.cert_nm;
		
							this.executeData_MEDI.file1 = data.file1;
							this.executeData_MEDI.file2 = data.file2;
							this.executeData_MEDI.cert_pw = data.cert_pw;
							break;
						case 'HIRA':
              this.hasHIRAAuth = false;
              this.endDt_HIRA = "";
              this.failRegHIRAMsg = "";
              this.failRegHIRAAuth = false;
							this.sendData_HIRA = {};
							this.executeData_HIRA = {};
							this.certNm_HIRA = "";
							
							this.sendData_HIRA.certNm = data.cert_nm; 
							this.sendData_HIRA.certPubDate = data.pub_dt;
							this.sendData_HIRA.certEndDate = data.end_dt;
							this.sendData_HIRA.certOrgNm = data.org_nm;
							this.sendData_HIRA.certPolicyId = data.oid;
							this.sendData_HIRA.certSerialNo = data.sn;
							this.sendData_HIRA.password = data.cert_pw;
		
							this.certNm_HIRA = data.cert_nm;
		
							this.executeData_HIRA.file1 = data.file1;
							this.executeData_HIRA.file2 = data.file2;
							this.executeData_HIRA.cert_pw = data.cert_pw;
							break; 
					}
				}
			} else if ('execute' == s_op) {
				if (errYn == 'N') {
					
					sendData.signCert = data.DER2PEM;
					sendData.signPri = data.KEY2PEM;
					
					this.authDataArr.push(sendData);
					
				}
			} 

		},
		fnNxDownload : function (){
			location.href = "/files/ExAdapter_Web_Setup_20200611.exe";
		},
		AuthRegist : async function(executeDatas, sendDatas){
			this.authDataArr = [];
			let result ;
			for(const [i, executeData] of executeDatas.entries()){
				var inJson = new Object();
				inJson.orgCd = 'common';
				inJson.svcCd = 'getCertInfo';
				inJson.appCd = 'ant';
				inJson.signCert = executeData.file1;
				inJson.signPri = executeData.file2;
				inJson.signPw = executeData.cert_pw;
				
				result = await this.loadNxClient('execute', JSON.stringify(inJson), null, sendDatas[i]);
			}
			var sendDataObj = new Object();
			sendDataObj["scrapCertAuthDtoList"] = this.authDataArr;
			
			this.sendCertData(JSON.stringify(sendDataObj));
			return result;
		},
		sendCertData : function (v_sendData){
			this.$cAxios.post(this.prefix + "/auth/regist", v_sendData, {
				headers:{
					'Content-Type' : "application/json",
				}
			}).then((res)=>{
				let errorList = res.data;
				if(errorList.length > 0){
					alert("인증정보 등록중 문제가 발생하였습니다. \n 인증서등록상태를 확인해주세요");
				}else{
					alert("인증정보가 등록되었습니다. 등록 상태를 확인해주세요.");
				}
				
				this.initInquriy();
				for(let key in errorList){
					let errCode = errorList[key].code;
					let errMsg = errorList[key].message;

					switch(errCode){
					  case -5:
              this.failRegHOMETAXAuth = true;
              this.failRegHOMETAXMsg = ": " + errMsg;
              break;
						case -1:
							this.failRegNHISAuth = true;
							this.failRegNHISMsg = ": " + errMsg;
							break;
						case -2:
							this.failRegNPSAuth = true;
							this.failRegNPSMsg = ": " + errMsg;
							break;
						case -3:
							this.failRegMEDIAuth = true;
							this.failRegMEDIMsg = ": " + errMsg;
							break;
						case -4:
							this.failRegHIRAAuth = true;
							this.failRegHIRAMsg = ": " + errMsg;
							break;
					}	
				}
			}).catch((err)=>{

			}).finally(()=>{
			});

		},
		selectAll: function(){
			this.checkedList = [];
			if(!this.allSelected){
				let chkbox = document.getElementsByName("chkbox");
				for(var i = 0 ; i <chkbox.length ; i++){
					this.checkedList.push(chkbox[i].value);
				}
			}
			this.allSelected = true;
		},
		regAuthCheck : function(scrapSites){
			for(var i = 0 ; i < scrapSites.length ; i++){
				var site = scrapSites[i];
				switch(site){
          case "HOMETAX" :
            if(!this.hasHOMETAXAuth) return false;
            break;
					case "NHIS" :
						if(!this.hasNHISAuth) return false;
						break;
					case "NPS" :
						if(!this.hasNPSAuth) return false;
						break;
					case "MEDI" :
						if(!this.hasMEDIAuth) return false;
						break;
					case "HIRA" :
						if(!this.hasHIRAAuth) return false;
						break;
					default : 
						return false;
				}
			}
			return true;
		},
    verificationInProgressCheck : function(scrapSites){
      var delAble = true;
      for(var i = 0 ; i < scrapSites.length ; i ++) {
        var site = scrapSites[i];
        switch (site) {
          case "HOMETAX" :
            if (this.status_HOMETAX == 'UNVALID') delAble = false;
            break;
          case "NHIS" :
            if (this.status_NHIS == 'UNVALID') delAble = false;
            break;
          case "NPS" :
            if (this.status_NPS == 'UNVALID') delAble = false;
            break;
          case "MEDI" :
            if (this.status_MEDI == 'UNVALID') delAble = false;
            break;
          case "HIRA" :
            if (this.status_HIRA == 'UNVALID') delAble = false;
            break;
        }
      }

      return delAble;
    }
	},
	created(){
		this.initInquriy();
	}
}	
</script>

<style>
.hr-title {
	position: relative;
	top:25px;
	padding-bottom:20px;
	border-top: 2px solid;
}
.text-left {
	float:left;
	text-align:left;
}
</style>